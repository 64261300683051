import React from 'react'
import Allroutes from './Components/Allroutes'
import Header from './Components/Header'
import Hero from './Components/Hero'



const App = () => {
  return (
    <div>
      <Header />
      <Allroutes />
    </div>
  )
}

export default App

