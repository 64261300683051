import React from 'react'
import styled from 'styled-components'
import images from "./Assets/codelablogo.png"

const Header = () => {
  return (
    <Container>
        <Wrapper>
            <Logo src={images}/>
        </Wrapper>
    </Container>
  )
}

export default Header
const Logo = styled.img`
    height: 35px;
`
const Wrapper = styled.div`
    width: 95%;
    display: flex;
    height: 100%;
    align-items: center;
    /* background-color: red; */
`

const Container = styled.div`
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    background-color: black;
`